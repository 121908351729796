/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With their confident attitude, good business sense and love of music, Texans need to keep their senses sharp and make sure their hearing is in good health. Texas has a lot of its citizens in noisy occupations and when you have to be alert in business conversations, it makes sense to protect your hearing as much as possible. Whether they are in Galveston or Abilene, hear.com can help Texans have great hearing abilities."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-huge-drag-on-texans-lifestyles",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-huge-drag-on-texans-lifestyles",
    "aria-label": "a huge drag on texans lifestyles permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Huge Drag on Texans’ Lifestyles"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’re at work or home, hearing loss can be a huge issue for Texans. The state is the epicenter of the country’s oil and gas sector, which means that thousands of Texans are exposed to high noise levels at on-shore drilling stations and off-shore rigs, refineries, in the transportation sector and construction sites."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Live music is a big part of Texas’ entertainment scene, especially in cities like Austin. In the state, people tune into TV and radio in huge numbers as a big part of their entertainment. Around 600,000 Texans hunt regularly, which puts them at risk of regular gunshot discharges."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is a state where individuals are exposed to high noise levels daily, but need to protect their hearing ability to enjoy life to the fullest. With the right hearing loss treatment and technology, Texans can prevent hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-in-texas",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-in-texas",
    "aria-label": "hearing loss in texas permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss in Texas"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s no surprise that we deal with thousands of customers from Texas every week who seek hearing aids to deal with their hearing loss. Parts of the Longhorn State have some of the loudest locations in the country. For instance, Houston, TX regularly tops the list of the country’s noisiest cities, possibly due to residential and industrial activities happening at the same time. It’s obvious that millions of Texans are at risk of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "About 10 percent of Americans suffer from hearing impairment, which translates into roughly 3 million Texans. And 25 percent of the American population suffer from tinnitus (ringing in the ears), which bumps up the statewide total by another million or so."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, we know that hearing aids can help many of these people restore their hearing, but only 16 percent of Americans investigate what can help them with their hearing issues. You can guarantee that wherever you are in Texas, neighbors and coworkers are not actively finding ways to improve their hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we know that simple remedies can be applied for most of these people with hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help",
    "aria-label": "how hearcom can help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Hear.com Can Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, what can be done for the community in Texas who have partial to severe hearing loss? Modern technology can deliver impressive results for most sufferers if they contact audiologists and make the most of the options available."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com can help Texans in this way. We have created partnerships with leading audiologists all over Texas, with over 80 partners in the state and more signing up continuously. This means that you’ll find audiologists from Dallas-Fort Worth to Houston, and there are specialists in small towns of Texas too. When we arrange a consultation for you, you will be able to have your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing tested"), " by an audiologist or hearing specialist."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After that, they will talk to you about your options and test out a few hearing aids to find the ones that perfectly suit your needs. Options include smartphone-compatible devices and ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "hearing aids with Bluetooth"), " to connect you to smart audio systems. And when you’ve picked a device, you can try it for 45 days or trade it in for a different model if necessary."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "see-an-expert-in-your-area",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#see-an-expert-in-your-area",
    "aria-label": "see an expert in your area permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "See an Expert in Your Area"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you like the sound of getting professional hearing loss services in Texas, all you need to do is complete our online questionnaire. After entering a few pieces of information, we’ll arrange a time and location for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "That way, you can find a hearing loss solution in no time!"), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
